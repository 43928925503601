import axios from "axios"
import { useFormik } from "formik"
import { useCookies } from "react-cookie"
import * as yup from "yup"


export  default function AdminUpdateQuestion(){

    const[cookies] = useCookies()
    const accessToken = cookies["accessToken"]
    const formik = useFormik({
        initialValues:{
            "id":"",
            "solution":""
        },
        validationSchema: yup.object({
            id: yup.string().required("Id is required"),
            solution: yup.string().required("Solution is required"),
          
        }),
        onSubmit: data =>{

            axios.put(`${process.env.REACT_APP_BACKEND_BASE_URL}/admin/addSolution`,  
               {
                id: data.id,
                solution : data.solution.trim()
               },{
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            })
                .then(response => {
                  alert(response.data)
                })
                .catch(error => {
                  
                    alert("Failed");
                });

        }
    })



    return(
        <div className="container-fluid">
       <form onSubmit={formik.handleSubmit}>
       <input type="number" name="id" onChange={formik.handleChange} style={{width:"100%", height:"40px", backgroundColor:"white", color:"black", marginTop:"30px",marginBottom:"20px"}}  required ></input>
        <textarea id="textArea" name="solution" onChange={formik.handleChange} style={{width:"100%", height:"60vh"}} color="white" placeholder="Solution">
    </textarea>
    <button className="btn btn-danger" style={{width:"100%"}}>Add Solution</button>

       </form>
    </div>
    )


}