import React, { useEffect, useState } from 'react';
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useCookies } from "react-cookie";
import "./Login.css";
import "./Login1.css";

export default function Login() {
    const [loginActive, setActivity] = useState(true);
    const [cookies, setCookies, removeCookies] = useCookies();
    const [subject, setSubject] = useState("");
    const [isMaturant, setIsMaturant] = useState(true); // State to track Maturant selection
    const navigate = useNavigate();

    const getValidationSchema = (isMaturant) => {
        return yup.object({
            telephone: yup.string()
            .matches(/^\d{9}$/, "Telephone must be exactly 9 digits")
            .required("Telephone is required"),
            name: yup.string().min(3, "Too short username").required("Username is required"),
            password: yup.string().required("Password is required").min(5, "Too short password"),
            selectSubject: isMaturant ? yup.string().required('You should choose a subject') : yup.string().notRequired(),
            confirmPassword: yup.string()
                .oneOf([yup.ref('password'), null], 'Passwords must match')
                .required('Please confirm your password')
        });
    };

    const formik = useFormik({
        initialValues: {
            telephone: "",
            name: "",
            password: "",
            gender: "m",
            confirmPassword: "",
            selectSubject: "kosvoa",
            role: "maturant"
        },
        validationSchema: getValidationSchema(isMaturant),
        onSubmit: user => {
            axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/signup`, user)
                .then(response => {
                    setCookies("role", user.role);
                    setCookies("telephone", user.telephone);
                    setCookies("accessToken", response.data.accessToken);
                    setCookies("gender", response.data.gender);
                    setCookies("name", user.name);
                    navigate("/home");
                })
                .catch(error => {
                    document.getElementById("incorrectSignUp").classList.remove("d-none");
                    document.getElementById("teleponeSignUp").value = "";
                });
        }
    });

    const loginFormik = useFormik({
        initialValues: {
            telephone: "",
            password: ""
        },
        validationSchema: yup.object({
            telephone: yup.string()
            .matches(/^\d{9}$/, "Telephone must be exactly 9 digits")
            .required("Telephone is required"),
            password: yup.string().required("Password is required")
        }),
        onSubmit: user => {
            axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/login`, user)
                .then(response => {
                    setCookies("role", response.data.role);
                    setCookies("telephone", user.telephone);
                    setCookies("accessToken", response.data.accessToken);
                    setCookies("gender", response.data.gender);
                    setCookies("name", response.data.username);
                    navigate("/home");
                })
                .catch(error => {
                    document.getElementById("incorrectLogin").classList.remove("d-none");
                });
        }
    });

    function removeIncorrect() {
        document.getElementById("incorrectLogin").classList.add("d-none");
    }

    function removeIcorrectSignUp() {
        document.getElementById("incorrectSignUp").classList.add("d-none");
    }

    useEffect(() => {
        formik.setValues(prevValues => ({
            ...prevValues,
            selectSubject: isMaturant ? '' : 'not required',
            role: isMaturant ? 'maturant' : 'semimaturant'
        }));
    }, [isMaturant]);

    return (
        <div className='top-main firstDiv' style={{ marginRight: "3%", position: "", top: "40px", left: "7%", marginBottom: "100px" }}>
            <div className={`main container-fluid loginForm`} style={{}}>
                {
                    (loginActive ?
                        <div className='d-flex continer-fluid '   >
                            <div className='form-login container-fluid' style={{ border: "", width: "600px" }}>
                                <form className='form' style={{ margin: "10%" }} autoComplete='off' onSubmit={loginFormik.handleSubmit}>
                                    <h2 >Hyni</h2>
                                    <div className='' style={{ marginTop: "60px" }}>
                                        <div style={{ width: "100%", }}>
                                            <input type='text' style={{ height: "100%", padding: "1px", fontSize: "18px" }} onFocus={removeIncorrect} onChange={loginFormik.handleChange} name='telephone' required ></input>
                                            <label className='username'>Telefoni</label>
                                        </div>
                                        <div>
                                            <span className='iconat'><i className="bi bi-person-fill"></i></span>
                                        </div>
                                        <span className='registerSpan'>{loginFormik.errors.telephone}</span>

                                    </div>
                                    <div className='' style={{ marginTop: "50px" }}>
                                        <div style={{ width: "100%" }}>
                                            <input type="password" style={{ height: "100%", marginBottom: "1px", fontSize: "18px" }} onFocus={removeIncorrect} onChange={loginFormik.handleChange} required name='password'></input>
                                            <label>Fjalëkalimi</label>
                                        </div>
                                        <span className='iconat'><i className="bi bi-lock-fill"></i></span>
                                    </div>
                                    <span className='registerSpan'>{loginFormik.errors.password}</span>

                                    <div className='d-none' id='incorrectLogin' style={{ marginTop: "-10px", textAlign: "center" }}>
                                        <span style={{ color: "red", textAlign: "center" }}>Gabim fjalëkalimi ose emaili!</span>
                                    </div>

                                    <button style={{ marginTop: "30px" }} className='btn1'>Hyni</button>
                                </form>
                                <p style={{ marginTop: "10px" }}>Nuk keni llogari <span style={{ color: "#0ef" }}><a onClick={() => setActivity(!loginActive)}>Regjistrohuni</a></span></p>

                            </div>

                        </div>
                        :
                        <div className='d-flex '  >
                            <div className='form-login' style={{ border: "", width: "600px" }}>
                                <form className='form' style={{ margin: "10%" }} autoComplete='off' onSubmit={formik.handleSubmit}>
                                    <h2 >Regjistrohuni</h2>
                                    <div className='' style={{ marginTop: "55px" }}>
                                        <input type="text" style={{ height: "100%", fontSize: "20px" }} id="teleponeSignUp" name='telephone' onChange={formik.handleChange} onFocus={removeIcorrectSignUp}  required></input>
                                        <label className='username' >Telefoni</label>
                                        <div>
                                            <span className='iconat'><i className="bi bi-envelope-fill"></i></span>
                                        </div>
                                        <span className='registerSpan'>{formik.errors.telephone}</span>

                                    </div>
                                    <div className='' style={{ marginTop: "30px" }}>
                                        <input type="text" style={{ height: "100%", fontSize: "20px" }} name="name" onChange={formik.handleChange} required></input>
                                        <label className='username'>Përdoruesi</label>
                                        <div>
                                            <span className='iconat'><i className="bi bi-person-fill"></i></span>
                                        </div>
                                        <span className='registerSpan'>{formik.errors.name}</span>

                                    </div>
                                    <div className='' style={{ marginTop: "30px" }}>
                                        <input type="password" style={{ height: "100%", fontSize: "20px" }} name='password' onChange={formik.handleChange} required></input>
                                        <label>Fjalëkalimi</label>
                                        <div>
                                            <span className='iconat'><i className="bi bi-lock-fill"></i></span>
                                        </div>
                                        <span className='registerSpan'>{formik.errors.password}</span>
                                    </div>
                                    <div className='' style={{ marginTop: "30px" }}>
                                        <input type="password" style={{ height: "100%", fontSize: "20px" }} name='confirmPassword' onChange={formik.handleChange} required></input>
                                        <label>Konfirmo Fjalëkalimin</label>
                                        <div>
                                            <span className='iconat'><i className="bi bi-lock-fill"></i></span>
                                        </div>
                                        <span className='registerSpan'>{formik.errors.confirmPassword}</span>
                                    </div>
                                    <div className='d-flex' style={{ marginTop: "0px" }}>
                                        <div className='d-flex' style={{ marginRight: "20px", fontSize: "20px" }}>
                                            <input
                                                type="radio"
                                                id="maturant"
                                                name="role"
                                                value="maturant"
                                                checked={formik.values.role === "maturant"}
                                                onChange={(e) => { formik.handleChange(e); setIsMaturant(true); }}
                                            />
                                            <label htmlFor="male" style={{ all: "unset" }}><span>Maturant</span></label>
                                        </div>
                                        <div className='d-flex' style={{ fontSize: "20px" }}>
                                            <input
                                                type="radio"
                                                id="semimaturant"
                                                name="role"
                                                value="semimaturant"
                                                checked={formik.values.role === "semimaturant"}
                                                onChange={(e) => { formik.handleChange(e); setIsMaturant(false); }}
                                            />
                                            <label htmlFor="female" style={{ all: "unset" }}><span>Semimaturant</span></label>
                                        </div>
                                    </div>
                                    {isMaturant && ( // Render subject dropdown only if Maturant is selected
                                        <div>
                                            <div style={{ marginTop: "0px", marginBottom: "20px", marginLeft: "-2px", marginTop:"4px" }}>
                                                <div className="dropdown" style={{ display: "flex", flexDirection: "row" }}>
                                                    <button data-bs-toggle="dropdown" className="btn  " style={{ minWidth: "160px", backgroundColor: "#17344a", color: "white", }} name='selectSubject'>Lënda<span><i className="bi bi-caret-up-fill" style={{ fontSize: "12px", marginLeft: "2px" }}></i></span></button><span style={{ marginLeft: "20px", paddingTop: "5px" }}>{subject}</span>

                                                    <div className="dropdown-menu dropdown-menu-dark" style={{ cursor: "pointer" }}>
                                                        <div className="dropdown-item " onClick={() => {
                                                            formik.setFieldValue('selectSubject', 'Informatike')
                                                            setSubject("Informatike")
                                                        }} >
                                                            Informatikë
                                                        </div>
                                                        <div className="dropdown-item" onClick={() => {
                                                            formik.setFieldValue('selectSubject', 'Kimi')
                                                            setSubject("Kimi")
                                                        }}>
                                                            Kimi
                                                        </div>
                                                        <div className="dropdown-item" onClick={() => {
                                                            formik.setFieldValue('selectSubject', 'Fizike')
                                                            setSubject("Fizike")
                                                        }}>
                                                            Fizikë
                                                        </div>
                                                        <div className="dropdown-item" onClick={() => {
                                                            formik.setFieldValue('selectSubject', 'Biologji')
                                                            setSubject("Biologji")
                                                        }}>
                                                            Biologji
                                                        </div>
                                                        <div className="dropdown-item" onClick={() => {
                                                            formik.setFieldValue('selectSubject', 'Gjeografi')
                                                            setSubject("Gjeografi")
                                                        }}>
                                                            Gjeografi
                                                        </div>
                                                        <div className="dropdown-item " onClick={() => {
                                                            formik.setFieldValue('selectSubject', 'Psikologji')
                                                            setSubject("Psikologji")
                                                        }}>
                                                            Psikologji
                                                        </div>
                                                        <div className="dropdown-item" onClick={() => {
                                                            formik.setFieldValue('selectSubject', 'Histori')
                                                            setSubject("Histori")
                                                        }}>
                                                            Histori
                                                        </div>
                                                        <div className="dropdown-item" onClick={() => {
                                                            formik.setFieldValue('selectSubject', 'Edukate Qytetare')
                                                            setSubject("Edukate Qytetare")
                                                        }}  >
                                                            Edukatë Qytetare
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ marginTop: "42px" }}>
                                                <span className='registerSpan' >{formik.errors.selectSubject}</span>
                                            </div>
                                        </div>
                                    )}
                                    <div className='d-flex' style={{ marginTop: "0px" }}>
                                        <div className='d-flex' style={{ marginRight: "20px", fontSize: "20px" }}>
                                            <input
                                                type="radio"
                                                id="male"
                                                name="gender"
                                                value="m"
                                                checked={formik.values.gender === "m"}
                                                onChange={formik.handleChange}
                                            />
                                            <label htmlFor="male" style={{ all: "unset" }}><span>Mashkull</span></label>
                                        </div>
                                        <div className='d-flex' style={{ fontSize: "20px" }}>
                                            <input
                                                type="radio"
                                                id="female"
                                                name="gender"
                                                value="f"
                                                checked={formik.values.gender === "f"}
                                                onChange={formik.handleChange}
                                            />
                                            <label htmlFor="female" style={{ all: "unset" }}><span>Femër</span></label>
                                        </div>
                                    </div>
                                    <div className='d-none' id='incorrectSignUp' style={{ marginTop: "10px", textAlign: "center" }}>
                                        <span style={{ color: "red", textAlign: "center" }}>Përdoruesi egziston!</span>
                                    </div>

                                    <button style={{ marginTop: "30px" }} className='btn1'>Regjistrohuni</button>
                                </form>
                                <p style={{ marginTop: "2px" }}>Keni llogari <span style={{ color: "#0ef" }}><a onClick={() => setActivity(!loginActive)}>Login</a></span></p>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
}
