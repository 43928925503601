import { Link, Routes, Route } from "react-router-dom";
import NavBar from "./components/NavBar/NavBar";
import "./App.css"
import Login from "./pages/Login/Login";
import Quizz from "./pages/Kuizi/Quizz";
import UserAccount from "./pages/Account/UserAccount";
import { useCookies } from "react-cookie";
import QuizzChoise from "./pages/Kuizi/QuizzChoise";
import { bool } from "yup";
import Home from "./pages/Home/Home";
import MainAdminPage from "./pages/Admin/MainAdminPage";
import AddQuestion from "./pages/Admin/AddQuestion";
import AllUser from "./pages/Admin/AllUser";
import StatusVerifiedUsers from "./pages/Admin/StatusVerifiedUsers";
import Suggesion from "./pages/Login/Suggesion";
import Verifye from "./pages/Admin/Verifye";
import AddText from "./pages/Admin/AddText";
import Contact from "./pages/Home/Contact";
import AdminUpdateQuestion from "./pages/Admin/AdminUpdateQuestion";
import QuizzChoiseSemimature from "./pages/Kuizi/QuizzChoiseSemimature";
import AddSemimaturaQuestion from "./pages/Admin/AddSemimaturaQuestion";
import QuizzSemimatura from "./pages/Kuizi/QuizzSemimatura";
import AdminUpdateQuestionSemimatura from "./pages/Admin/AdminUpdateQuestionSemimatura";

function App() {

  const [cookies, setCookies, removeCookies] = useCookies()
  const roli = cookies["role"]
  console.log("roli "+ roli)


  return (
    <div id="app">
      <header>
        <NavBar></NavBar>
      </header>
      <main>
        <section className="container-fluid sectioni" style={{ paddingBottom: "150px" }}>
          <Routes >
          
            {/* <Route path="/" element={
              (cookies.telephone == undefined) ? <Home></Home> : <QuizzChoiseSemimature></QuizzChoiseSemimature> } className="" ></Route> */}
             <Route path="/" element={
              (cookies.telephone == undefined) ? <Home></Home> : (roli == "maturant")?<QuizzChoise></QuizzChoise> : <QuizzChoiseSemimature></QuizzChoiseSemimature>
            } className="" ></Route>
            <Route path="/home" element={
              (cookies.telephone == undefined) ? <Home></Home> : (roli == "maturant")?<QuizzChoise></QuizzChoise> : <QuizzChoiseSemimature></QuizzChoiseSemimature>
            } className="" ></Route>
            <Route path="/about" element={<div>About</div>} className=""></Route>
            <Route path="/login" element={<Login></Login>} className=""> </Route>
            <Route path="/suggesion" element={<Suggesion></Suggesion>}></Route>

            <Route path="/logout" element={<UserAccount></UserAccount>}></Route>
            {/* <Route path="/question/1/:subject" element={<Quizz></Quizz>}></Route> */}
            <Route path="/question/1/:subject" element={
            (roli == "maturant")?<Quizz></Quizz>:<QuizzSemimatura></QuizzSemimatura>}></Route>
            <Route path="/contact" element={<Contact></Contact>}></Route>

            <Route path="/admin" element={<MainAdminPage></MainAdminPage>}>
              <Route path="updateQuestion" element={<AdminUpdateQuestion></AdminUpdateQuestion>}></Route>
              <Route path="updateQuestionSemimatura" element={<AdminUpdateQuestionSemimatura></AdminUpdateQuestionSemimatura>}></Route>
              <Route path="addText" element={<AddText></AddText>}></Route>
              <Route path="addQuesiton" element={<AddQuestion></AddQuestion>}></Route>
              <Route path="addQuesitonSemimature" element={<AddSemimaturaQuestion></AddSemimaturaQuestion>}></Route>
              <Route path="allUsers" element={<AllUser></AllUser>}>
                <Route path="" element={<StatusVerifiedUsers></StatusVerifiedUsers>}></Route>
                <Route path=":status" element={<StatusVerifiedUsers></StatusVerifiedUsers>}></Route>
                <Route path="verifye" element={<Verifye></Verifye>}></Route>
              </Route>
            </Route>
          </Routes>
        </section>
      </main>
    </div>
  );
}

export default App;