
import brainImage from './bac1-removebg-preview.png';
import style from "./Home.module.css"
import { Navigate, useNavigate } from 'react-router-dom';

export default function Home() {
    const navigate = useNavigate()

    function handleLoginClick(){

        navigate("/login")
    }

    return (
        <div className={` ${style.main}`}>
            <div className={`${style.image}`}>
                <img src={brainImage} style={{ width: "100%", opacity: "0.8" }}></img>
            </div>
            <div className={`${style.text}`}>
                <p>Përgadituni për të kaluar testin më të rëndësishëm në jetë lehtë!</p>
            </div>
            <div style={{display:"flex", justifyContent:"center"}}>
                
             <button className={` ${style.btn}`} onClick={handleLoginClick}>Hyni për të vazhduar</button>

            </div>
            
        </div>
    )
}