import style from "./Main.module.css"
import { Link, Outlet } from "react-router-dom"
export default function MainAdminPage(){


    return(
        <div>
        <div className={style.mainDiv}>
        <Link to="allUsers" ><button className={'btn btn-danger'} style={{padding:"10px"}}>AllUsers</button></Link>    
        <Link  to="addQuesiton"><button className={'btn btn-primary'} style={{margin:"10px"}}>AddQuestion</button></Link>    
        <Link  to="addQuesitonSemimature"><button className={'btn btn-danger'} style={{margin:"10px"}}>AddQuestionSemimature</button></Link>    
        <Link  to="addText"><button className={'btn btn-info'} style={{margin:"10px"}}>AddText</button></Link>    
        <Link to="updateQuestion"><button className={`btn btn-secondary`}>UpdateQuestion</button></Link>   
        <Link to="updateQuestionSemimatura"><button className={`btn btn-info`}>UpdateQuestionSemimatura</button></Link>    
 
        </div>
        <div>
            <Outlet></Outlet>
        </div>
        </div>
    )
}