
import style from "./AllUser.module.css"
import { Link, Outlet } from "react-router-dom"

export default function AllUser(){





    return(
        <div>
            <div className={`${style.mainDiv}`}>
             <Link to="verified"><button className="btn btn-success" style={{margin:"30px"}}>VerifiedUsers</button></Link>   
              <Link to="unverified"><button className="btn btn-danger" style={{margin:"30px"}}>UnverifiedUsers</button></Link> 
              <Link to="verifye"><button className="btn btn-warning" style={{margin:"30px"}}>Verifie</button></Link> 

            </div>
            <Outlet></Outlet>
        </div>
    )
}