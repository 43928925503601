import { useParams } from "react-router-dom"
import { useCookies } from "react-cookie"
import axios from "axios"
import { useEffect, useState } from "react"

export default function StatusVerifiedUsers(){
    

    const params = useParams()

    const hasParams = (params.status != undefined)? true: false
    console.log(hasParams + "Drenica")
    const[cookies] = useCookies()
    const accessToken = cookies["accessToken"]
    const [users, setUsers] = useState([]);
    var count =0;
    

    function loadUsers(){
       if(hasParams){
        axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/admin/users/${(params.status == "verified")?true:false}`,{
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        }).then( rez => setUsers(rez.data))
       }
       else{
        
        axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/admin/users`,{
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        }).then( rez => setUsers(rez.data))
       }
    }
   
    


    useEffect( () =>{
        loadUsers()
    },[params])

 



    function handleUnverify(e){

        console.log(e.target.id)
        axios.put(`${process.env.REACT_APP_BACKEND_BASE_URL}/admin/users/changeVerification`,{
            "telephone": e.target.id
        },{
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        }).then(() => loadUsers())

    }
    return(
        <div className="conatiner">
            <table className="table table-dark table-striped">
                <thead>
                    <tr>    
                        <td>#</td>
                        <td>Name</td>
                        <td>Phone</td>
                        <td>Unverifie</td>
                        <td>Verifie</td>
                    </tr>
                </thead>
                <tbody>
                   {
                    users.map(user =>
                        <tr>
                            <td>{count = count+1}</td>
                            <td>{user.name}</td>
                            <td>{user.telephone}</td>
                            <td><button id={user.telephone} className="btn btn-danger" onClick={handleUnverify} disabled={user.verified ? false : true}>UnVerifie</button></td>
                            <td><button id={user.telephone} className="btn btn-success" onClick={handleUnverify} disabled={user.verified ? true : false} >Verifie</button></td>

                        </tr>
                    )
                   }
                </tbody>
            </table>


        </div>
    )
}