import { useEffect, useRef, useState } from "react"
import styles from "./Quizz.module.css"
import { useLayoutEffect } from 'react';


import axios from "axios"
import { json, useParams } from "react-router-dom"
import { useCookies } from "react-cookie"
export default function QuizzSemimatura() {

    const [questionNumber, setQuestionNumber] = useState(1)
    const [correctOption, setCorrectOption] = useState("")
    const [questionName, setQuestionName] = useState("")
    const [text, setText] = useState("")
    const [testOrImage, setTestOrImage] = useState("")
    const [userOption, setUserOption] = useState()
    const [allQuestionNumber, setAllQuestionNumber] = useState([])
    const [question, setQuestion] = useState()
    const params = useParams()
    const containerRef = useRef(null); // Ref for the scroll container
    const [userAnswer, setUserAnswer] = useState([])
    const [cookies, setCookies, removeCookies] = useCookies()
    const telephone = cookies["telephone"]
    const accessToken = cookies["accessToken"];
    const [rightOrintation, setRightOrintation] = useState(true)
    const [downOrientation, setDownOrientation] = useState(true)
    const width = window.innerWidth;
    const [solution, setSolution] = useState();

    function handleNext() {
        window.MathJax.typesetClear()
        const buttons = document.querySelectorAll('button');
        buttons.forEach(button => {
            button.classList.remove(styles.correctSelected, styles.wrongSelect);
            button.disabled = false

        });

        if (questionNumber == allQuestionNumber.length) {

        }

        else {
            setQuestionNumber(questionNumber + 1)

        }

    }

    function handlePrew(e) {

        const buttons = document.querySelectorAll('button');
        buttons.forEach(button => {
            button.classList.remove(styles.correctSelected, styles.wrongSelect);
            button.disabled = false
        });
        if (questionNumber == 1) {

        }
        else {
            setQuestionNumber(questionNumber - 1)

        }
    }

    async function handleOption(e) {

        // const element = document.getElementsByClassName("Quizz_wrongSelect__HB0F2")[0];
        // if (element) {
        //     element.classList.remove("Quizz_wrongSelect__HB0F2");

        // }
        const selected = e.target.value;
        if (correctOption == selected) {
            e.target.classList.add(styles.correctSelected);
        } else {
            document.querySelector(`button[value="${correctOption}"]`).classList.add(styles.correctSelected);
            e.target.classList.add(styles.wrongSelect);
        }
        const buttons = document.querySelectorAll(".option");
        buttons.forEach(btn => btn.disabled = true);
        const moveButtons = document.querySelectorAll(".move");
        moveButtons.forEach(btn => btn.disabled = false);
        const correctOpt = (selected === correctOption);
        changeButtonColor(correctOpt);



        axios.put(`${process.env.REACT_APP_BACKEND_BASE_URL}/questionAnswered/${telephone}`, {
            telephone: telephone,
            id: questionNumber,
            subject: params.subject,
            correct: (selected === correctOption)
        }, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        });

    }




    function disableMoveButton() {
        const moveButton = document.querySelectorAll(".move")
        moveButton.forEach(btn => btn.disabled = true)

    }

    function handleSpecificButton(e) {
        // alert(e.target.value)
        const button = document.querySelectorAll(".option")
        button.forEach(button => {
            button.classList.remove(styles.correctSelected, styles.wrongSelect);
            button.disabled = false
        });
        setQuestionNumber(parseInt(e.target.value))
    }

    //Function to handle direct Color Change 
    function changeButtonColor(correctnes) {

        const button = document.getElementById(questionNumber);
        button.classList.remove("btn-danger")

        if (correctnes == true) {
            button.classList.remove(styles.wrongButton)
            button.classList.add(styles.correctButton)
        }
        else {
            button.classList.remove(styles.correctButton)
            button.classList.add(styles.wrongButton)
        }




    }

    function handleColorSpecificButton() {
        handleScrollBar();
        if (width > 600) {
            setRightOrintation(!rightOrintation);
        } else {
            setDownOrientation(!downOrientation);
        }
    
        // Ensure userAnswer array is available and has elements
        if (userAnswer && userAnswer.length > 0) {
            userAnswer.forEach(answers => {
                const button = document.getElementById(`${answers.question.id}`);
                if (button) { // Check if button exists
                    if (answers.correct) {
                        button.classList.remove("btn-danger");
                        button.classList.add(styles.correctButton);
                    } else {
                        button.classList.remove("btn-danger");
                        button.classList.add(styles.wrongButton);
                    }
                }
            });
        }
    }

    useEffect(() => {
        const subject = params.subject
        axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/semimaturaQuestion/${questionNumber}/${subject}`, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        })
            .then(rez => {
                const unauthorizedElements = document.querySelectorAll(".unathorized");

                unauthorizedElements.forEach(element => {
                    element.classList.remove("d-none");
                });
                document.getElementById("pagesaInfo").classList.add("d-none")


                if (rez.data.solution != undefined) {
                    document.getElementById("solution").classList.remove("d-none")
                    setSolution(rez.data.solution)
                }
                else {
                    document.getElementById("solution").classList.add("d-none")

                }


                setCorrectOption(rez.data.correct)

                // setCorrectOption(rez.data.correct)
                setQuestion(rez.data);
                setQuestionName(rez.data.question);
                const textDiv = document.getElementById("text")
                var imageElement = document.getElementById("image");
                var imazhi = document.getElementById("imazh")
                var modali = document.getElementById("mod")
                if (rez.data.text != undefined) {
                    setTestOrImage("Tekstin")
                    setText(rez.data.text)

                    modali.style.minWidth = "70%"
                    imazhi.classList.add("d-none")
                    imageElement.style.display = "none"
                    textDiv.style.display = "block"
                    textDiv.innerHTML = `${rez.data.text}`
                }
                else if (rez.data.image != undefined) {
                    setTestOrImage("Imazhin")
                    setText(rez.data.image)
                    var imageElement = document.getElementById("image");
                    textDiv.style.display = "none"
                    imageElement.style.display = "block"
                    imageElement.src = `${rez.data.image}`



                }
                else {
                    setText("")
                }
                // setCorrectOption(rez.data[0].correct);
            })
            .catch(error => {
                const unauthorizedElements = document.querySelectorAll(".unathorized");

                unauthorizedElements.forEach(element => {
                    element.classList.add("d-none");
                });
                document.getElementById("pagesaInfo").classList.remove("d-none")
            });

        axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/semimaturaQuestion/questionNumber/${subject}`, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        }).then(rez => {
            var data = rez.data;
            data = data.count
            const questionNumbers = [];
            for (let i = 1; i <= data; i++) {
                questionNumbers.push(i);
            }
            setAllQuestionNumber(questionNumbers);
        })
            .catch(error => {
                console.error("Error fetching question numbers:", error);
            });

        axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/questionAnswered/${cookies.telephone}/${subject}`, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        }).then(rez => {

            setUserAnswer(rez.data)

            // alert(userAnswer.length)
        })


        disableMoveButton()


    }, [questionNumber]);

    useEffect(() => {
        if (typeof window?.MathJax !== "undefined") {
            window.MathJax.typesetClear()
            window.MathJax.typeset()
        }
    }, [question])


    useEffect(() => {

        handleScrollBar()

    }, [containerRef]);


    function handleScrollBar() {
        const container = containerRef.current;
        if (container) {
            function isScrollbarActive() {

                return container.scrollHeight > container.clientHeight;


            }

            function toggleScrollbarClass() {
                if (isScrollbarActive()) {
                    container.classList.remove(styles.hideScrollbar);
                } else {
                    container.classList.add(styles.hideScrollbar);


                }
            }

            window.addEventListener('resize', toggleScrollbarClass);
            toggleScrollbarClass();

        }
    }


    return (
        <div className={styles.mainDiv}>

            <div className={`d-flex ${styles.kryesori} `} >
                <div className={`d-flex ${styles.answeresKryesori}`} style={{ marginLeft: "2px", marginBottom: "10px" }}>
                    <div className={`collapse ${styles.answerButtton}`} id="work" style={{ marginRight: "3px", marginLeft: "0px", marginBottom: "5px" }}>
                        <div ref={containerRef} className={`${styles.scrollDiv}`}>
                            {allQuestionNumber.map(item => (
                                <button key={item} value={item} id={item} className="btn btn-secondary specificButton" onClick={handleSpecificButton} style={{ margin: "3px", width: "50px", height: "50px", fontWeight: "400", fontSize: "25px", display:"flex", justifyContent:"center" }}>{item}</button>
                            ))}
                        </div>
                    </div>
                    <button className={`btn ${styles.showButton}`} style={{ height: "101%", width: "20px", backgroundColor: "#124366", textAlign: "center", paddingRight: "20px" }} data-bs-toggle="collapse" data-bs-target="#work" onClick={handleColorSpecificButton}><span className="text-center">{
                        (width > 600) ? (rightOrintation) ? <i className="bi bi-caret-left-fill" style={{ color: "white", fontSize: "20px" }}></i> : <i className="bi bi-caret-right-fill" style={{ color: "white", fontSize: "20px" }}></i> : (downOrientation) ? <i className="bi bi-caret-up-fill" style={{ color: "white", fontSize: "20px" }}></i> : <i className="bi bi-caret-down-fill" style={{ color: "white", fontSize: "20px" }}></i>



                    }
                    </span></button>


                </div>
                <div className={`container-fluid ${styles.questionDiv}`} style={{ border: "", marginLeft: "0px" }}>
                    <div className="card " style={{ padding: "2px" }}>
                        <div className={`card-header ${styles.cardHeader}`} >
                            <button className="btn unathorized " data-bs-target="#textImageModal" data-bs-toggle="modal" style={{
                                backgroundColor: "#17344a", // Setting background color
                                color: "white", // Setting text color
                                float: "right",
                                marginTop: "10px",
                                display: text == "" ? "none" : "block",// Conditionally setting display property
                                whiteSpace: "noWrap", /* Preventing line breaks */
                            }}>Shiko {testOrImage}</button>
                            <div className={`card-header ${styles.cardHeader}`} style={{ paddingLeft: "0" }}>
                                <span style={{ verticalAlign: "center" }} className={`${styles.numberText} unathorized`}>{questionNumber}.</span>
                                <span className={` ${styles.questionText} unathorized`} style={{ verticalAlign: "center", hyphens: "auto" }}>
                                    <h3 className={`card-title ${styles.questionText}`} style={{ display: "inline", verticalAlign: "center" }}>
                                        {question && question.question.startsWith("<img") ? (
                                            // If question.question starts with "<img", render it as HTML
                                            <div dangerouslySetInnerHTML={{ __html: question.question }} />
                                        ) : (
                                            // If question.question doesn't start with "<img", render it as text
                                            question && question.question
                                        )}
                                    </h3>
                                </span>
                            </div>


                            <div className="modal fade" id="textImageModal" >
                                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable " id="mod" >
                                    <div className="modal-content">

                                        <div className="modal-header">
                                            <button className="btn-close" data-bs-dismiss="modal"></button>
                                        </div>
                                        <div className={`modal-body ${styles.modalBody}  ${styles.Quizz_modalBody__lMIwh}`} id="main" style={{ color: "black",paddingLeft:"0" }}>
                                            <div id="text" ></div>
                                            <div id="imazh"> 
                                                <div style={{ display: testOrImage === "Text" ? "none" : "block" }}>
                                                    <div style={{ width: "100%", height: "100%", minHeight: "450px", display: "flex", justifyContent: "center", alignItems: "center", paddingLeft: "25px", paddingRight: "25px" }}>
                                                        <img id="image" style={{ width: "100%", height: "100%" }} />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="modal-footer">
                                            <button className="btn" style={{ backgroundColor: "#17344a", color: "white" }} data-bs-dismiss="modal">Close</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`card-body ${styles.cardBody}`}>
                        <div className="d-none" id="pagesaInfo" style={{ minHeight: "468px", fontSize: "1.5em" }}>
                                <div>
                                Për shkak të mirëmbajtjes së aplikacionit, që të keni qasje në të gjitha pyetjet e tjera, ju duhet të dërgoni 1 euro në njërin nga numrat e telefonit, me që rast ju do të keni akses 3 mujor në të gjitha pyetjet<br></br><br></br>
                                Nëse jeni operator i Valës <span style={{ borderBottom: "2px solid #0bf" }}>045-533-383.</span>Shtypni butonin për thirrje në këtë numër: <span>*121*45533383*1*00000#</span> <br/><br></br>
                                    Nese jeni operator i Ipkos <span style={{ borderBottom: "2px solid #0bf" }}>043-507-727.</span>Shtypni butonin për thirrje në këtë numër: <span> *124*1*043507727#</span>,pranoni konfirmimin dhe thirrni përsëri: <span>*124#</span> <br/>

                                </div>
                                <br></br>
                                <div>
                                Pasi të jetë realizuar verifikimi nga ana jonë, që të keni qasje në pyetjet e tjera, ju fillimisht duhet të dilni (log out) dhe të hyni përsëri.
                                </div>
                                <br></br>
                                Për çdo pengesë mund të na kontaktoni përmes emailit: <span style={{ borderBottom: "2px solid #0bf" }}>maturaks2024@gmail.com</span>.
                                <br/>
                                <div>
                                Pyetje të reja do të vijnë çdo ditë.
                                </div>
                                <br/>
                                <div>Paçi fat!</div>
                            </div>
                        
                            <dl className="unathorized">
                                <dt style={{ marginBottom: "2%" }}>
                                    {question && question.a.startsWith("<img") ? (
                                        // If question.a starts with "<img", render it as HTML
                                        <button className={`btn ${styles.textBtn} w-100 rounded-3 buttoni option`} style={{ width: "100%", border: "2px solid red" }} onClick={handleOption} value="a">
                                            <div id="imazhi" dangerouslySetInnerHTML={{ __html: question.a }} style={{ pointerEvents: "none" }}></div>
                                        </button>
                                    ) : (
                                        // If question.a doesn't start with "<img", render it as text
                                        <button className={`btn ${styles.textBtn} w-100 rounded-3 buttoni option`} style={{}} onClick={handleOption} value="a">
                                            {question && question.a}
                                        </button>
                                    )}
                                </dt>
                                <dt style={{ marginBottom: "2%" }}>
                                    {question && question.b.startsWith("<img") ? (
                                        // If question.a starts with "<img", render it as HTML
                                        <button className={`btn ${styles.textBtn} w-100 rounded-3 buttoni option`} onClick={handleOption} value="b">
                                            <div dangerouslySetInnerHTML={{ __html: question.b }} style={{ pointerEvents: "none" }}></div>
                                        </button>
                                    ) : (
                                        // If question.a doesn't start with "<img", render it as text
                                        <button className={`btn ${styles.textBtn} w-100 rounded-3 buttoni option`} onClick={handleOption} value="b">
                                            {question && question.b}
                                        </button>
                                    )}
                                </dt>
                                <dt style={{ marginBottom: "2%" }}>
                                    {question && question.c.startsWith("<img") ? (
                                        // If question.a starts with "<img", render it as HTML
                                        <button className={`btn ${styles.textBtn} w-100 rounded-3 buttoni option`} onClick={handleOption} value="c">
                                            <div dangerouslySetInnerHTML={{ __html: question.c }} style={{ pointerEvents: "none" }}></div>
                                        </button>
                                    ) : (
                                        // If question.a doesn't start with "<img", render it as text
                                        <button className={`btn ${styles.textBtn} w-100 rounded-3 buttoni option`} onClick={handleOption} value="c">
                                            {question && question.c}
                                        </button>
                                    )}
                                </dt>
                                <dt style={{ marginBottom: "2%" }}>
                                    {question && question.d.startsWith("<img") ? (
                                        // If question.a starts with "<img", render it as HTML
                                        <button className={`btn ${styles.textBtn} w-100 rounded-3 buttoni option`} onClick={handleOption} value="d">
                                            <div dangerouslySetInnerHTML={{ __html: question.d }} style={{ pointerEvents: "none" }}></div>
                                        </button>
                                    ) : (
                                        // If question.a doesn't start with "<img", render it as text
                                        <button className={`btn ${styles.textBtn} w-100 rounded-3 buttoni option`} onClick={handleOption} value="d">
                                            {question && question.d}
                                        </button>
                                    )}
                                </dt>
                            </dl>
                        </div>
                        <div className={`card-footer d-flex justify-content-between ${styles.cardFooter}`}>
                            <button className="btn btn-primary move unathorized" onClick={handlePrew}>Pas</button>

                            <button className="btn btn-primary move unathorized" onClick={handleNext}>Para</button>
                        </div>

                    </div>
                    <button id="solution" className="btn d-none unathorized" data-bs-target="#register" data-bs-toggle="modal" style={{ textAlign: "center", width: "100%", marginTop: "10px", backgroundColor: "#124366", color: "white", }}>Shiko zgjidhjen</button>
                    <div class="modal fade" id="register">
                        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div class="modal-content">

                                <div class="modal-header" style={{ backgroundColor: "#081722", color: "white" }}>
                                    <h2>Zgjidhja</h2>
                                    <button class="btn-close" data-bs-dismiss="modal" style={{ backgroundColor: "white", color: "red" }}></button>
                                </div>

                                <div class="modal-body" style={{ backgroundColor: "#081b29", color: "white", lineHeight: "1.8" }}>
                                    {solution}
                                </div>

                                <div class="modal-footer" style={{ backgroundColor: "#081722" }}>
                                    <button class="btn " style={{ backgroundColor: "#17344a", color: "white" }} data-bs-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


