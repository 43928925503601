import { useEffect, useState } from "react"
import "./QuizzChoiseStyle.css"
import { Link } from "react-router-dom"
import axios from "axios"
import { useCookies } from "react-cookie"

export default function QuizzChoise() {

    const [subjectSelected, setSubjectSelected] = useState("")
    const [allSubjects, setAllSubjects] = useState(["Gjuhe Shqipe", "Gjuhe Angleze", "Matematike"])
    const [cookies, setCookies, removeCookies] = useCookies()
    const accessToken = cookies["accessToken"]; // Ensure accessToken is defined

    const [gjuheShqipe, setGjuheShqipe] = useState({
        correct: 0,
        wrong: 0,
        total: 0

    })

    const [subject, setSubject] = useState({
        correct: 0,
        wrong: 0,
        total: 0
    });


    const [matamatike, setMatematike] = useState({
        correct: 0,
        wrong: 0,
        total: 0
    })

    const [gjuheAngleze, setGjuheAngleze] = useState({
        correct: 0,
        wrong: 0,
        total: 0
    })




    function setStatus(subject) {
        const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/question/${subject}`
        switch (subject) {
            case "Gjuhe Shqipe":
                axios.get(url, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                }
                )
                    .then(rez => {

                        setGjuheShqipe(prevState => ({
                            ...prevState,
                            correct: rez.data.correct,
                            wrong: rez.data.wrong,
                            total: rez.data.total
                        }));
                    });

                break;
            case "Gjuhe Angleze":
                axios.get(url, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                }
                )
                    .then(rez => {

                        setGjuheAngleze(prevState => ({
                            ...prevState,
                            correct: rez.data.correct,
                            wrong: rez.data.wrong,
                            total: rez.data.total
                        }));
                    });

                break;
            case "Matematike":
                axios.get(url, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                })
                    .then(rez => {

                        setMatematike(prevState => ({
                            ...prevState,
                            correct: rez.data.correct,
                            wrong: rez.data.wrong,
                            total: rez.data.total
                        }));
                    });


                break;
            case subjectSelected:
                axios.get(url, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                })
                    .then(rez => {

                        setSubject(prevState => ({
                            ...prevState,
                            correct: rez.data.correct,
                            wrong: rez.data.wrong,
                            total: rez.data.total
                        }));
                    });

                break;
        }
    }


    function getSubject() {

        axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/selectQustion/${cookies.telephone}`, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        })
            .then(rez => {
                setSubjectSelected(rez.data.selectedSubject)
                allSubjects.push(rez.data.selectedSubject)

            });
    }

    useEffect(() => {
        getSubject()
        allSubjects.forEach(subject => {
            setStatus(subject)
        })
    }, [subjectSelected]);





    return (
        <div className="mainDiv container-fluid">
            <div className="course-conatiner container-fluid" style={{ margin: "auto" }}>
                <div style={{ color: "white" }}>
                    <a style={{color:"white"}} href="https://invite.viber.com/?g2=AQAZYAKIcyBgT1MCP8hqnPGb1yYzHQtFBYySKFuYOi5iK9TyW8A7CFcAvtD%2BcrJp">Bashkangjituni ne grupin ne Viber!</a>
                </div>
                <div className="course-box">
                    <div className="course">
                        <Link to="/question/1/Gjuhe Shqipe" className="courseLink">
                            <div className="courseBackGround"></div>

                            <div className="courseTitle">
                                Gjuhë Shqipe
                            </div>

                            <div className="info">
                                <div className="infoItem">
                                    Pyetje Totale:
                                    <span className="infoItem-focus">
                                        {gjuheShqipe.total}
                                    </span>
                                </div>
                                <div className="infoItem">
                                    Saktë:
                                    <span className="infoItem-focus">
                                        {gjuheShqipe.correct}
                                    </span>
                                </div>
                                <div className="infoItem">
                                    Gabim:
                                    <span className="infoItem-focus">
                                        {gjuheShqipe.wrong}

                                    </span>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="course">
                        <Link to="/question/1/Gjuhe Angleze" className="courseLink">
                            <div className="courseBackGround"></div>

                            <div className="courseTitle">
                                Gjuhë Angleze
                            </div>

                            <div className="info">
                                <div className="infoItem">
                                    Pyetje Totale:
                                    <span className="infoItem-focus">
                                        {gjuheAngleze.total}
                                    </span>
                                </div>
                                <div className="infoItem">
                                    Saktë:
                                    <span className="infoItem-focus">
                                        {gjuheAngleze.correct}
                                    </span>
                                </div>
                                <div className="infoItem">
                                    Gabim:
                                    <span className="infoItem-focus">
                                        {gjuheAngleze.wrong}

                                    </span>
                                </div>
                            </div>
                        </Link>
                    </div>


                    <div className="course">
                        <Link to="/question/1/Matematike" className="courseLink">
                            <div className="courseBackGround"></div>

                            <div className="courseTitle">
                                Matematikë
                            </div>

                            <div className="info">
                                <div className="infoItem">
                                    Pyetje Totale:
                                    <span className="infoItem-focus">
                                        {matamatike.total}

                                    </span>
                                </div>
                                <div className="infoItem">
                                    Saktë:
                                    <span className="infoItem-focus">
                                        {matamatike.correct}
                                    </span>
                                </div>
                                <div className="infoItem">
                                    Gabim:
                                    <span className="infoItem-focus">
                                        {matamatike.wrong}

                                    </span>
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className="course">
                        <Link to={`/question/1/${subjectSelected}`} className="courseLink">
                            <div className="courseBackGround"></div>

                            <div className="courseTitle">
                                {subjectSelected}
                            </div>

                            <div className="info">
                                <div className="infoItem">
                                    Pyetje Totale:
                                    <span className="infoItem-focus">
                                        {subject.total}

                                    </span>
                                </div>
                                <div className="infoItem">
                                    Saktë:
                                    <span className="infoItem-focus">
                                        {subject.correct}
                                    </span>
                                </div>
                                <div className="infoItem">
                                    Gabim:
                                    <span className="infoItem-focus">
                                        {subject.wrong}

                                    </span>
                                </div>
                            </div>
                        </Link>
                    </div>





                </div>
            </div>
        </div>
    )
}