import axios from "axios"
import { useState } from "react"
import { useCookies } from "react-cookie"


export default function AddText(){

    const[cookies] = useCookies()
    const accessToken = cookies["accessToken"]

    function getText(){

       const text =  document.getElementById("textArea").value
       const id = (parseInt)(document.getElementById("textId").value)
        if(text != ""  && id !=NaN){
            
            axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/admin/addText`,  {
                id: id,
                text:text.trim()
            },{
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            })
                .then(response => {
                  alert(response.data)
                  getLastId()
                })
                .catch(error => {
                  
                    alert("Failed");
                });

        }
        else{
            console.log("Incorrect Id or text")
        }
    }

    function getLastId(){

        axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/admin/maxTextId`, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        }).then(rez => {    
            
            if(rez.data == "NaN"){
                document.getElementById("textId").value = 1


            }
            else{
                document.getElementById("textId").value = rez.data+1
            }

           
        })
    }


    
    
    useState(() =>{
     getLastId()
    },[])

    return(
        <div className="container-fluid">
        <input type="number" style={{width:"100%", height:"40px", backgroundColor:"white", color:"black", marginTop:"30px",marginBottom:"20px"}} id="textId" required disabled></input>
        <textarea id="textArea" style={{width:"100%", height:"90vh"}} color="white" placeholder="Question Text">
    </textarea>
    <button className="btn btn-danger" onClick={getText} style={{width:"100%"}}>Add Text</button>

    </div>
    )
}