import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useState } from "react"; // Import useState
import "./NavBar.css";
 
export default function NavBar() {
    const [cookies] = useCookies(); // No need to use setCookies or removeCookies here
    const [collapsed, setCollapsed] = useState(true); // State to manage navbar collapse
    

    // Function to toggle navbar collapse
    const toggleCollapse = () => {
        setCollapsed(!collapsed);
    };

    // Function to handle link click and collapse the navbar
    const handleLinkClick = () => {
        setCollapsed(true);
    };

    function handleToLogin(){
        


    }
    return (
        <nav className="navbar navbar-dark navbar-expand-lg">
            <div className="container-fluid">
                <h2 className="navbar-brand">MATURA</h2>
                <button className="navbar-toggler" onClick={toggleCollapse}>
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className={`collapse navbar-collapse justify-content-end ${collapsed ? '' : 'show'}`} id="nav">
                    <ul className="navbar-nav">
                        <li className="nav-item button" style={{margin:"auto"}}>
                            <Link to="/home" className="btn btn-primary nav-link ite.item" style={{color:"white",padding:"10px 20px",}} onClick={handleLinkClick}>Home</Link>
                        </li>
                        <li className="nav-item button" style={{margin:"auto"}}>
                            <Link to="/contact" className="btn btn-primary nav-link" style={{color:"white", padding:"10px 20px"}} onClick={handleLinkClick}>Kontakti</Link>
                        </li>
                        <li className="nav-item button text-danger" style={{margin:"auto"}}>
                            {
                                (cookies.telephone === undefined) ? 
                                    <Link to="/login" className="btn btn-primary nav-link" style={{color:"white",padding:"10px 20px"}} onClick={handleLinkClick}>Login</Link> :
                                    <Link to="/logout" className="btn btn-primary nav-link" style={{color:"white",padding:"10px 20px"}} onClick={handleLinkClick}>Llogaria</Link>
                            }
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}