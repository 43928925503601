// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
    margin-bottom: 20px /* Optional: Add margin to the bottom of the navbar */
}

.navbar-brand {
    margin-right: auto; /* Push the brand to the left */
}

.navbar-nav {
    display: flex;
    align-items: center; /* Vertically center the items */
}

.nav-item {
    margin-right: 50px; /* Adjust spacing between navbar items */
    font-size: 20px;
    color: white;
    padding: 20px;
}

`, "",{"version":3,"sources":["webpack://./src/components/NavBar/NavBar.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB,EAAE,qDAAqD;AAC7E;;AAEA;IACI,kBAAkB,EAAE,+BAA+B;AACvD;;AAEA;IACI,aAAa;IACb,mBAAmB,EAAE,gCAAgC;AACzD;;AAEA;IACI,kBAAkB,EAAE,wCAAwC;IAC5D,eAAe;IACf,YAAY;IACZ,aAAa;AACjB","sourcesContent":[".navbar {\n    margin-bottom: 20px /* Optional: Add margin to the bottom of the navbar */\n}\n\n.navbar-brand {\n    margin-right: auto; /* Push the brand to the left */\n}\n\n.navbar-nav {\n    display: flex;\n    align-items: center; /* Vertically center the items */\n}\n\n.nav-item {\n    margin-right: 50px; /* Adjust spacing between navbar items */\n    font-size: 20px;\n    color: white;\n    padding: 20px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
