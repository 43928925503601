// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `


.Home_main__3ilkD{
    width: 900px;
    max-width: 100%;
    margin: auto;
    margin-top: 50px;
    
    
}

.Home_image__dxFyw{
    width: 100%;
}


.Home_text__YlQ2v{
    color: white;
    font-size: 30px;
    font-weight: bold;

}


 .Home_btn__PmZsX {
  width: 300px;
  margin: auto;
  height: 50px;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  color: rgba(255,255,255,0.9);
  border-radius: 50px;
  background: linear-gradient(-45deg, #ff3d77, #338aff);
  background-size: 600%;
  animation: Home_anime__znEfV 16s linear infinite;
}
@keyframes Home_anime__znEfV {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Home/Home.module.css"],"names":[],"mappings":";;;AAGA;IACI,YAAY;IACZ,eAAe;IACf,YAAY;IACZ,gBAAgB;;;AAGpB;;AAEA;IACI,WAAW;AACf;;;AAGA;IACI,YAAY;IACZ,eAAe;IACf,iBAAiB;;AAErB;;;CAGC;EACC,YAAY;EACZ,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,4BAA4B;EAC5B,mBAAmB;EACnB,qDAAqD;EACrD,qBAAqB;EAEb,gDAAoC;AAC9C;AAaA;EACE;IACE,2BAA2B;EAC7B;EACA;IACE,6BAA6B;EAC/B;EACA;IACE,2BAA2B;EAC7B;AACF","sourcesContent":["\n\n\n.main{\n    width: 900px;\n    max-width: 100%;\n    margin: auto;\n    margin-top: 50px;\n    \n    \n}\n\n.image{\n    width: 100%;\n}\n\n\n.text{\n    color: white;\n    font-size: 30px;\n    font-weight: bold;\n\n}\n\n\n .btn {\n  width: 300px;\n  margin: auto;\n  height: 50px;\n  font-size: 22px;\n  font-weight: bold;\n  text-align: center;\n  color: rgba(255,255,255,0.9);\n  border-radius: 50px;\n  background: linear-gradient(-45deg, #ff3d77, #338aff);\n  background-size: 600%;\n  -webkit-animation: anime 16s linear infinite;\n          animation: anime 16s linear infinite;\n}\n\n@-webkit-keyframes anime {\n  0% {\n    background-position: 0% 50%;\n  }\n  50% {\n    background-position: 100% 50%;\n  }\n  100% {\n    background-position: 0% 50%;\n  }\n}\n@keyframes anime {\n  0% {\n    background-position: 0% 50%;\n  }\n  50% {\n    background-position: 100% 50%;\n  }\n  100% {\n    background-position: 0% 50%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `Home_main__3ilkD`,
	"image": `Home_image__dxFyw`,
	"text": `Home_text__YlQ2v`,
	"btn": `Home_btn__PmZsX`,
	"anime": `Home_anime__znEfV`
};
export default ___CSS_LOADER_EXPORT___;
