// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.UserAccount_data__Lpt27{

}


.UserAccount_main__wxjyJ{
    margin-top: 40px !important;
    color: white;
    border: solid #0ef 2px;
    width: 400px;
    max-width: 100%;
    margin: auto;
    background-color: #081b29;


}

.UserAccount_divImage__qu\\+CS{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 50px;
}

.UserAccount_image__44N0N{
    border-radius: 50%;
    padding: 1px;
    border: 2px solid black;
    margin: auto;
    text-align: center;
    width: 50%;
}

.UserAccount_data__Lpt27{
    text-align: end;
}


.UserAccount_btn1__wP-xT {
    position: relative;
    width: 100%;
    height: 45px;
    background: transparent;
    border: 2px solid #0ef;
    outline: none;
    border-radius: 40px;
    cursor: pointer;
    font-size: 16ox;
    color: #fff;
    font-weight: 600;
    z-index: 1;
    overflow: hidden;
}

.UserAccount_btn1__wP-xT::before {
    content: '';
    position: absolute;
    top: -100%;
    left: 0;
    width: 100%;
    height: 300%;
    background: linear-gradient(#081b29, #0ef, #081b29, #0ef);
    z-index: -1;
    transition: .5s;
}

.UserAccount_btn1__wP-xT:hover::before {
    top: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/Account/UserAccount.module.css"],"names":[],"mappings":";;AAEA;;AAEA;;;AAGA;IACI,2BAA2B;IAC3B,YAAY;IACZ,sBAAsB;IACtB,YAAY;IACZ,eAAe;IACf,YAAY;IACZ,yBAAyB;;;AAG7B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,uBAAuB;IACvB,YAAY;IACZ,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,eAAe;AACnB;;;AAGA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,sBAAsB;IACtB,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,eAAe;IACf,WAAW;IACX,gBAAgB;IAChB,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,UAAU;IACV,OAAO;IACP,WAAW;IACX,YAAY;IACZ,yDAAyD;IACzD,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,MAAM;AACV","sourcesContent":["\n\n.data{\n\n}\n\n\n.main{\n    margin-top: 40px !important;\n    color: white;\n    border: solid #0ef 2px;\n    width: 400px;\n    max-width: 100%;\n    margin: auto;\n    background-color: #081b29;\n\n\n}\n\n.divImage{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin-top: 30px;\n    margin-bottom: 50px;\n}\n\n.image{\n    border-radius: 50%;\n    padding: 1px;\n    border: 2px solid black;\n    margin: auto;\n    text-align: center;\n    width: 50%;\n}\n\n.data{\n    text-align: end;\n}\n\n\n.btn1 {\n    position: relative;\n    width: 100%;\n    height: 45px;\n    background: transparent;\n    border: 2px solid #0ef;\n    outline: none;\n    border-radius: 40px;\n    cursor: pointer;\n    font-size: 16ox;\n    color: #fff;\n    font-weight: 600;\n    z-index: 1;\n    overflow: hidden;\n}\n\n.btn1::before {\n    content: '';\n    position: absolute;\n    top: -100%;\n    left: 0;\n    width: 100%;\n    height: 300%;\n    background: linear-gradient(#081b29, #0ef, #081b29, #0ef);\n    z-index: -1;\n    transition: .5s;\n}\n\n.btn1:hover::before {\n    top: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"data": `UserAccount_data__Lpt27`,
	"main": `UserAccount_main__wxjyJ`,
	"divImage": `UserAccount_divImage__qu+CS`,
	"image": `UserAccount_image__44N0N`,
	"btn1": `UserAccount_btn1__wP-xT`
};
export default ___CSS_LOADER_EXPORT___;
