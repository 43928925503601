import { useEffect, useState } from "react"
import "./QuizzChoiseStyle.css"
import { Link } from "react-router-dom"
import axios from "axios"
import { useCookies } from "react-cookie"

export default function QuizzChoiseSemimature() {

    const [subjectSelected, setSubjectSelected] = useState("")
    const [allSubjects, setAllSubjects] = useState(["Gjuhe Shqipe", "Gjuhe Angleze", "Matematike", "Informatike", "Fizike", "Kimi", "Biologji", "Histori", "Gjeografi"])
    const [cookies, setCookies, removeCookies] = useCookies()
    const accessToken = cookies["accessToken"]; // Ensure accessToken is defined

    const [gjuheShqipe, setGjuheShqipe] = useState({
        correct: 0,
        wrong: 0,
        total: 0

    })
    const [matamatike, setMatematike] = useState({
        correct: 0,
        wrong: 0,
        total: 0
    })

    const [gjuheAngleze, setGjuheAngleze] = useState({
        correct: 0,
        wrong: 0,
        total: 0
    })



    const [informatike, setInformatike] = useState({
        correct: 0,
        wrong: 0,
        total: 0
    })

    const [fizike, setFizike] = useState({
        correct: 0,
        wrong: 0,
        total: 0
    })

    const [kimi, setKimi] = useState({
        correct: 0,
        wrong: 0,
        total: 0
    })

    const [biologji, setBiologji] = useState({
        correct: 0,
        wrong: 0,
        total: 0
    })

    const [histori, setHistori] = useState({
        correct: 0,
        wrong: 0,
        total: 0
    })


    const [gjeografi, setGjeografi] = useState({
        correct: 0,
        wrong: 0,
        total: 0
    })




    function setStatus(subject) {
        const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/semimaturaQuestion/${subject}`
        switch (subject) {
            case "Gjuhe Shqipe":
                axios.get(url, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                }
                )
                    .then(rez => {

                        setGjuheShqipe(prevState => ({
                            ...prevState,
                            correct: rez.data.correct,
                            wrong: rez.data.wrong,
                            total: rez.data.total
                        }));
                    });

                break;
            case "Gjuhe Angleze":
                axios.get(url, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                }
                )
                    .then(rez => {

                        setGjuheAngleze(prevState => ({
                            ...prevState,
                            correct: rez.data.correct,
                            wrong: rez.data.wrong,
                            total: rez.data.total
                        }));
                    });

                break;
            case "Matematike":
                axios.get(url, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                })
                    .then(rez => {

                        setMatematike(prevState => ({
                            ...prevState,
                            correct: rez.data.correct,
                            wrong: rez.data.wrong,
                            total: rez.data.total
                        }));
                    });
                break;
            case "Informatike":
                axios.get(url, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                })
                    .then(rez => {

                        setInformatike(prevState => ({
                            ...prevState,
                            correct: rez.data.correct,
                            wrong: rez.data.wrong,
                            total: rez.data.total
                        }));
                    });
                break;
            case "Fizike":
                axios.get(url, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                })
                    .then(rez => {

                        setFizike(prevState => ({
                            ...prevState,
                            correct: rez.data.correct,
                            wrong: rez.data.wrong,
                            total: rez.data.total
                        }));
                    });
                break;
            case "Kimi":
                axios.get(url, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                })
                    .then(rez => {

                        setKimi(prevState => ({
                            ...prevState,
                            correct: rez.data.correct,
                            wrong: rez.data.wrong,
                            total: rez.data.total
                        }));
                    });
                break;
            case "Biologji":
                axios.get(url, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                })
                    .then(rez => {

                        setBiologji(prevState => ({
                            ...prevState,
                            correct: rez.data.correct,
                            wrong: rez.data.wrong,
                            total: rez.data.total
                        }));
                    });
                break;
            case "Gjeografi":
                axios.get(url, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                })
                    .then(rez => {

                        setGjeografi(prevState => ({
                            ...prevState,
                            correct: rez.data.correct,
                            wrong: rez.data.wrong,
                            total: rez.data.total
                        }));
                    });
                break;
            case "Histori":
                axios.get(url, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                })
                    .then(rez => {

                        setHistori(prevState => ({
                            ...prevState,
                            correct: rez.data.correct,
                            wrong: rez.data.wrong,
                            total: rez.data.total
                        }));
                    });
                break;



        }
    }


    // function getSubject() {

    //     axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/selectQustion/${cookies.telephone}`, {
    //         headers: {
    //             'Authorization': `Bearer ${accessToken}`
    //         }
    //     })
    //         .then(rez => {
    //             setSubjectSelected(rez.data.selectedSubject)
    //             allSubjects.push(rez.data.selectedSubject)

    //         });
    // }

    useEffect(() => {
        // getSubject()
        allSubjects.forEach(subject => {
            setStatus(subject)
            console.log("Drenica")
        })
    }, []);





    return (
        <div className="mainDiv container-fluid">
            <div className="course-conatiner container-fluid" style={{ margin: "auto" }}>
            <div style={{ color: "white" }}>
                    <a style={{color:"white"}} href="https://invite.viber.com/?g2=AQBxkG5Px7XI9lMEm270PKgKBxToH%2BXN3xnjg8V4S681B4GZHrajCi02Y7aBvM4R">Bashkangjituni ne grupin ne Viber!</a>
                </div>
                <div className="course-box">
                    <div className="course">
                        <Link to="/question/1/Gjuhe Shqipe" className="courseLink">
                            <div className="courseBackGround"></div>

                            <div className="courseTitle">
                                Gjuhë Shqipe
                            </div>

                            <div className="info">
                                <div className="infoItem">
                                    Pyetje Totale:
                                    <span className="infoItem-focus">
                                        {gjuheShqipe.total}
                                    </span>
                                </div>
                                <div className="infoItem">
                                    Saktë:
                                    <span className="infoItem-focus">
                                        {gjuheShqipe.correct}
                                    </span>
                                </div>
                                <div className="infoItem">
                                    Gabim:
                                    <span className="infoItem-focus">
                                        {gjuheShqipe.wrong}

                                    </span>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="course">
                        <Link to="/question/1/Gjuhe Angleze" className="courseLink">
                            <div className="courseBackGround"></div>

                            <div className="courseTitle">
                                Gjuhë Angleze
                            </div>

                            <div className="info">
                                <div className="infoItem">
                                    Pyetje Totale:
                                    <span className="infoItem-focus">
                                        {gjuheAngleze.total}
                                    </span>
                                </div>
                                <div className="infoItem">
                                    Saktë:
                                
                                    <span className="infoItem-focus">
                                        {gjuheAngleze.correct}
                                    </span>
                                </div>
                                <div className="infoItem">
                                    Gabim:
                                    <span className="infoItem-focus">
                                        {gjuheAngleze.wrong}

                                    </span>
                                </div>
                            </div>
                        </Link>
                    </div>


                    <div className="course">
                        <Link to="/question/1/Matematike" className="courseLink">
                            <div className="courseBackGround"></div>

                            <div className="courseTitle">
                                Matematikë
                            </div>

                            <div className="info">
                                <div className="infoItem">
                                    Pyetje Totale:
                                    <span className="infoItem-focus">
                                        {matamatike.total}

                                    </span>
                                </div>
                                <div className="infoItem">
                                    Saktë:
                                    <span className="infoItem-focus">
                                        {matamatike.correct}
                                    </span>
                                </div>
                                <div className="infoItem">
                                    Gabim:
                                    <span className="infoItem-focus">
                                        {matamatike.wrong}

                                    </span>
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className="course">
                        <Link to="/question/1/Informatike" className="courseLink">
                            <div className="courseBackGround"></div>

                            <div className="courseTitle">
                                Informatike
                            </div>

                            <div className="info">
                                <div className="infoItem">
                                    Pyetje Totale:
                                    <span className="infoItem-focus">
                                        {informatike.total}

                                    </span>
                                </div>
                                <div className="infoItem">
                                    Saktë:
                                    <span className="infoItem-focus">
                                        {informatike.correct}
                                    </span>
                                </div>
                                <div className="infoItem">
                                    Gabim:
                                    <span className="infoItem-focus">
                                        {informatike.wrong}

                                    </span>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="course">
                        <Link to="/question/1/Fizike" className="courseLink">
                            <div className="courseBackGround"></div>

                            <div className="courseTitle">
                                Fizike
                            </div>

                            <div className="info">
                                <div className="infoItem">
                                    Pyetje Totale:
                                    <span className="infoItem-focus">
                                        {fizike.total}

                                    </span>
                                </div>
                                <div className="infoItem">
                                    Saktë:
                                    <span className="infoItem-focus">
                                        {fizike.correct}
                                    </span>
                                </div>
                                <div className="infoItem">
                                    Gabim:
                                    <span className="infoItem-focus">
                                        {fizike.wrong}

                                    </span>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="course">
                        <Link to="/question/1/Kimi" className="courseLink">
                            <div className="courseBackGround"></div>

                            <div className="courseTitle">
                                Kimi
                            </div>

                            <div className="info">
                                <div className="infoItem">
                                    Pyetje Totale:
                                    <span className="infoItem-focus">
                                        {kimi.total}

                                    </span>
                                </div>
                                <div className="infoItem">
                                    Saktë:
                                    <span className="infoItem-focus">
                                        {kimi.correct}
                                    </span>
                                </div>
                                <div className="infoItem">
                                    Gabim:
                                    <span className="infoItem-focus">
                                        {kimi.wrong}

                                    </span>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="course">
                        <Link to="/question/1/Biologji" className="courseLink">
                            <div className="courseBackGround"></div>

                            <div className="courseTitle">
                                Biologji
                            </div>

                            <div className="info">
                                <div className="infoItem">
                                    Pyetje Totale:
                                    <span className="infoItem-focus">
                                        {biologji.total}

                                    </span>
                                </div>
                                <div className="infoItem">
                                    Saktë:
                                    <span className="infoItem-focus">
                                        {biologji.correct}
                                    </span>
                                </div>
                                <div className="infoItem">
                                    Gabim:
                                    <span className="infoItem-focus">
                                        {biologji.wrong}

                                    </span>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="course">
                        <Link to="/question/1/Gjeografi" className="courseLink">
                            <div className="courseBackGround"></div>

                            <div className="courseTitle">
                                Gjeografi
                            </div>

                            <div className="info">
                                <div className="infoItem">
                                    Pyetje Totale:
                                    <span className="infoItem-focus">
                                        {gjeografi.total}

                                    </span>
                                </div>
                                <div className="infoItem">
                                    Saktë:
                                    <span className="infoItem-focus">
                                        {gjeografi.correct}
                                    </span>
                                </div>
                                <div className="infoItem">
                                    Gabim:
                                    <span className="infoItem-focus">
                                        {gjeografi.wrong}

                                    </span>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="course">
                        <Link to="/question/1/Histori" className="courseLink">
                            <div className="courseBackGround"></div>

                            <div className="courseTitle">
                                Histori
                            </div>

                            <div className="info">
                                <div className="infoItem">
                                    Pyetje Totale:
                                    <span className="infoItem-focus">
                                        {histori.total}

                                    </span>
                                </div>
                                <div className="infoItem">
                                    Saktë:
                                    <span className="infoItem-focus">
                                        {histori.correct}
                                    </span>
                                </div>
                                <div className="infoItem">
                                    Gabim:
                                    <span className="infoItem-focus">
                                        {histori.wrong}

                                    </span>
                                </div>
                            </div>
                        </Link>
                    </div>

                </div>
            </div>
        </div>
    )
}