
import style from "./Suggesion.module.css"
export default function Suggesion(){


    return(
        <div className="container" style={{ height:"75vh", display:"flex", flexDirection:"column", justifyContent:"center"}}>

            <div className={`${style.mainDiv}`}>
                <h1>Priteni Verifikimin!</h1>
                <h1 style={{fontSize:"25px", marginTop:"30px"}}>
                    Pershak te miremajtjes se aplikacionit, qe te keni qasje ne teste ju duhet te dergoni 2euro ne numrin e telefonit:<br/>
                    Nese jeni operator i Vala-se:<span style={{color:"red"}}>045-533-383</span>thirrni ketu: bllablabalb <br/>
                    Nese jeni operator i Ipko-se:<span style={{color:"red"}}>043-507-737</span>thirreni ketu: blablabalb <br/>
                     dhe pas kesaj accounti i juaj brenda 2h do te verifikohet, ku pastaj do mund te keni qasje ne teste. 

                </h1>
            </div>
        </div>
    )
}