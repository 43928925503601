import { useState } from "react"
import { useCookies } from "react-cookie"
import { useNavigate } from "react-router-dom"
import boyImage from "./boy.png"
import girlImage from "./girl1.png"
import style from "./UserAccount.module.css"


export default function UserAccount() {

    const [userDetails, setUserDetails] = useState()
    const [cookies, setCookies, removeCookies] = useCookies()
    const navigate = useNavigate()


    function handleLogOut() {
        removeCookies('telephone');
        removeCookies("accessToken")
        removeCookies("gender")
        removeCookies("name")
        navigate("/home")
    }


    return (
        <div className="container-fluid">

            <div className={`${style.main}`}>

                <div className={`${style.divImage}`}>
                    <img src={(cookies["gender"] == "m")? boyImage: girlImage} className={`${style.image}`}></img>
                </div>
                <div className={`row ${style.data}`} style={{marginLeft:"5px", marginRight:"20px"}}>
                    <p className="col"  style={{textAlign:"center"}}>Përdoruesi</p>
                    <p className="col"  style={{textAlign:"center", maxWidth:"150px"}}>{cookies["name"]}</p>
                </div>
                <div className={`row ${style.data}`} style={{marginLeft:"5px", marginRight:"20px"}}>
                    <p className="col" style={{textAlign:"center"}}>Telefoni</p>
                    <p className="col" style={{textAlign: "center", wordWrap: "break-word", maxWidth:"150px"}}>{cookies["telephone"]}</p>
                </div>
               <div style={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", padding:"20px", marginTop:"15px", marginBottom:"10px"}}>
               <button className={`${style.btn1}`} style={{width:"90%"}} onClick={handleLogOut}>LogOut</button>
               </div>
                
            </div>
          
        </div>
    )
}