// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.AllUser_mainDiv__8ELHB{
    margin-top: 20px;
    
    display: flex;
    justify-content: center;    
}`, "",{"version":3,"sources":["webpack://./src/pages/Admin/AllUser.module.css"],"names":[],"mappings":";;AAEA;IACI,gBAAgB;;IAEhB,aAAa;IACb,uBAAuB;AAC3B","sourcesContent":["\n\n.mainDiv{\n    margin-top: 20px;\n    \n    display: flex;\n    justify-content: center;    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainDiv": `AllUser_mainDiv__8ELHB`
};
export default ___CSS_LOADER_EXPORT___;
