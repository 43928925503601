import { useEffect, useState } from "react"
import axios from "axios";
import { useCookies } from "react-cookie";
import { useFormik } from "formik";

export default function Verifye() {
    const [user, setUser] = useState({});
    const [cookies] = useCookies();
    const accessToken = cookies["accessToken"];

    const formik = useFormik({
        initialValues: {
            telephone: ""
        },
        onSubmit: (data) => {
            axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/admin/specific/${data.telephone}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then(rez => {
                setUser(rez.data);
            }).catch(error => {
                console.error("Error fetching user data:", error);
            }).finally(() => {
                formik.resetForm(); // Reset the form values after successful or failed submission
            });
        }
    });

    function handleUnverify() {
        axios.put(`${process.env.REACT_APP_BACKEND_BASE_URL}/admin/users/changeVerification`, {
            "telephone": user.telephone
        }, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        }).then(() => loadUser());
    }

    function loadUser() {
        axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/admin/specific/${user.telephone}`, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        }).then(rez => setUser(rez.data));
    }

    return (
        <div className="conatiner">
            <form style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "20px" }} onSubmit={formik.handleSubmit}>
                <h2 style={{ color: "white" }}>
                    Phone
                </h2>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <input type="tel" style={{ padding: "0", border: "2px solid red", marginRight: "10px", paddingLeft: "4px", fontSize: "20px" }} name="telephone" onChange={formik.handleChange} value={formik.values.telephone}></input>
                    <button className="btn btn-primary">Search</button>
                </div>
            </form>
            <table className="table table-dark table-striped">
                <thead>
                    <tr>
                        <td>#</td>
                        <td>Name</td>
                        <td>Phone</td>
                        <td>Unverifie</td>
                        <td>Verifie</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td>{user.name}</td>
                        <td>{user.telephone}</td>
                        <td><button id={user.telephone} className="btn btn-danger" onClick={handleUnverify} disabled={!user.verified}>UnVerifie</button></td>
                        <td><button id={user.telephone} className="btn btn-success" onClick={handleUnverify} disabled={user.verified}>Verifie</button></td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}