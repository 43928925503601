import axios from "axios"
import { useFormik } from "formik"
import { useCookies } from "react-cookie"
import * as yup from "yup"

export default function AddSemimaturaQuestion() {


    const [cookies] = useCookies()
    const accessToken = cookies["accessToken"]

    const formik = useFormik({
        initialValues: {
            question: "",
            subject: "",
            a: "",
            b: "",
            c: "",
            d: "",
            correct: "",
            textimage: "",
            textid:"",
            type:"semimature"
        },
        validationSchema: yup.object({
            question: yup.string().required("Required"),
            subject: yup.string().required("Required"),
            a: yup.string().required("Required"),
            b: yup.string().required("Required "),
            c: yup.string().required("Required "),
            d: yup.string().required("Required "),
            correct: yup.string().required("Required "),
            
        }),
        onSubmit: question => {
            console.log("Submitting form..." + question);
            axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/admin/question`, question, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            })
                .then(rez => {
                    document.getElementById("question").value = "";
                    document.getElementById("a").value = "";
                    document.getElementById("b").value = "";
                    document.getElementById("c").value = "";
                    document.getElementById("d").value = "";
                    document.getElementById("image").value = "";
                    document.getElementById("textid").value=""
                    var correctRadio = document.getElementsByName("correct")
                    correctRadio.forEach( radio =>{
                        radio.checked = false
                    })

                    var subjectRadio = document.getElementsByName("subject")
                    subjectRadio.forEach( radio =>{
                        radio.checked = false
                    })


                }).catch(error => {
                    console.error("Error fetching user data:", error);
                }).finally(() => {
                    formik.resetForm(); // Reset the form values after successful or failed submission
                });
        }
    })


    return (
        <div style={{ backgroundColor: "#081b29", color: "white" }} className="container-fluid">

            <form onSubmit={formik.handleSubmit} >
                <table className="table  table-bordered container table-responsive  border-4" style={{ border: "2px solid #3b5175", maxWidth: "90%" }}>
                    <tr style={{ margin: "10px", }}>
                        <td style={{ width: "30%", height: "40px", borderRight: "2px solid #3b5175", textAlign: "center" }}>Pytje</td>
                        <td style={{ width: "100%", height: "40px", display: "flex" }}><input type="text" id="question" name="question" onChange={formik.handleChange} style={{ color: "white", backgroundColor: "#081b29" }}></input>
                            <span style={{ width: "fit-content", background: "red", color: "white" }}>{formik.errors.question}</span></td>
                    </tr>
                    <tr style={{ margin: "10px", }}>
                        <td style={{ width: "30%", height: "40px", borderRight: "2px solid #3b5175", textAlign: "center" }}>A</td>
                        <td style={{ width: "100%", height: "40px", display: "flex" }}><input type="text" id="a" name="a" onChange={formik.handleChange} style={{ color: "white", backgroundColor: "#081b29" }}></input>
                            <span style={{ width: "fit-content", background: "red", color: "white" }}>{formik.errors.a}</span>
                        </td>
                    </tr>
                    <tr style={{ margin: "10px", }}>
                        <td style={{ width: "30%", height: "40px", borderRight: "2px solid #3b5175", textAlign: "center" }}>B</td>
                        <td style={{ width: "100%", height: "40px", display: "flex" }}><input type="text" name="b" id="b" onChange={formik.handleChange} style={{ color: "white", backgroundColor: "#081b29" }}></input>
                            <span style={{ width: "fit-content", background: "red", color: "white" }}>{formik.errors.b}</span>
                        </td>
                    </tr>
                    <tr style={{ margin: "10px", }}>
                        <td style={{ width: "30%", height: "40px", borderRight: "2px solid #3b5175", textAlign: "center" }}>C</td>
                        <td style={{ width: "100%", height: "40px", display: "flex" }}><input type="text" name="c" id="c" onChange={formik.handleChange} style={{ color: "white", backgroundColor: "#081b29" }}></input>
                            <span style={{ width: "fit-content", background: "red", color: "white" }}>{formik.errors.c}</span>
                        </td>
                    </tr>
                    <tr style={{ margin: "10px", }}>
                        <td style={{ width: "30%", height: "40px", borderRight: "2px solid #3b5175", textAlign: "center" }}>D</td>
                        <td style={{ width: "100%", height: "40px", display: "flex" }}><input type="text" name="d" id="d" onChange={formik.handleChange} style={{ color: "white", backgroundColor: "#081b29" }}></input>
                            <span style={{ width: "fit-content", background: "red", color: "white" }}>{formik.errors.d}</span>
                        </td>
                    </tr>
                    <tr style={{ margin: "10px", }}>
                        <td style={{ width: "30%", height: "40px", borderRight: "2px solid #3b5175", textAlign: "center" }}>Image</td>
                        <td style={{ width: "100%", height: "40px", display: "flex" }}><input type="text" name="textimage" id="image" onChange={formik.handleChange} style={{ color: "white", backgroundColor: "#081b29" }}></input>
                        </td>
                    </tr>
                    <tr style={{ margin: "10px", }}>
                        <td style={{ width: "30%", height: "40px", borderRight: "2px solid #3b5175", textAlign: "center" }}>Text</td>
                        <td style={{ width: "100%", height: "40px", display: "flex" }}><input type="number" name="textid" id="textid" onChange={formik.handleChange} style={{ color: "white", backgroundColor: "#081b29" }}></input>
                        </td>
                    </tr>
                    <tr style={{ margin: "10px", }}>
                        <td style={{ width: "30%", height: "40px", borderRight: "2px solid #3b5175", textAlign: "center" }}>Correct</td>
                        <td style={{ width: "", display: "flex", padding: "" }}>
                            <div style={{ border: "2px solid #3b5175", padding: "20px", margin: "20px", display: "flex" }}>  <input type="radio" value="a" onChange={formik.handleChange} name="correct" id="a" />
                                <p for="a">A</p></div>
                            <div style={{ border: "2px solid #3b5175", padding: "20px", margin: "20px" }}>  <input value="b" onChange={formik.handleChange} style={{ width: "20px" }} type="radio" name="correct" id="a" />
                                <p for="a">B</p></div>
                            <div style={{ border: "2px solid #3b5175", padding: "20px", margin: "20px" }}>  <input type="radio" value="c" onChange={formik.handleChange} name="correct" id="a" />
                                <p for="a">C</p></div>
                            <div style={{ border: "2px solid #3b5175", padding: "20px", margin: "20px" }}>  <input type="radio" onChange={formik.handleChange} value="d" name="correct" id="a" />
                                <p for="a">D</p></div>
                        </td>
                        <span style={{ backgroundColor: "red", width: "100%", display: "block", textAlign: "center" }}>{formik.errors.correct}</span>

                    </tr>
                    <tr style={{ margin: "10px", }}>
                        <td style={{ width: "30%", height: "40px", borderRight: "2px solid #3b5175", textAlign: "center" }}>Subject</td>
                        <td style={{ width: "100%", display: "flex", padding: "" }}>
                            <div style={{ border: "2px solid #3b5175", padding: "20px", margin: "20px" }}>  <input type="radio" value="Gjuhe Shqipe" onChange={formik.handleChange} name="subject" id="a" />
                                <p for="a">Gjuhe Shqipe</p></div>
                            <div style={{ border: "2px solid #3b5175", padding: "20px", margin: "20px" }}>  <input type="radio" value="Matematike" onChange={formik.handleChange} name="subject" id="a" />
                                <p for="a">Matematike</p></div>
                            <div style={{ border: "2px solid #3b5175", padding: "20px", margin: "20px" }}>  <input type="radio" value="Gjuhe Angleze" onChange={formik.handleChange} name="subject" id="a" />
                                <p for="a">Anglisht</p></div>
                            <div style={{ border: "2px solid #3b5175", padding: "20px", margin: "20px" }}>  <input type="radio" value="Informatike" onChange={formik.handleChange} name="subject" id="a" />
                                <p for="a">Informatike</p></div>

                            <div style={{ border: "2px solid #3b5175", padding: "20px", margin: "20px" }}>  <input type="radio" value="Kimi" onChange={formik.handleChange} name="subject" id="a" />
                                <p for="a">Kimi</p></div>

                            <div style={{ border: "2px solid #3b5175", padding: "20px", margin: "20px" }}>  <input type="radio" value="Fizike" onChange={formik.handleChange} name="subject" id="a" />
                                <p for="a">Fizike</p></div>

                            <div style={{ border: "2px solid #3b5175", padding: "20px", margin: "20px" }}>  <input type="radio" value="Biologji" onChange={formik.handleChange} name="subject" id="a" />
                                <p for="a">Biologji</p></div>

                            <div style={{ border: "2px solid #3b5175", padding: "20px", margin: "20px" }}>  <input type="radio" value="Gjeografi" onChange={formik.handleChange} name="subject" id="a" />
                                <p for="a">Gjeografi</p></div>

                           
                            <div style={{ border: "2px solid #3b5175", padding: "20px", margin: "20px" }}>  <input type="radio" value="Histori" onChange={formik.handleChange} name="subject" id="a" />
                                <p for="a">Histori</p></div>

                           
                        </td>
                        <span style={{ backgroundColor: "red", width: "100%", display: "block", textAlign: "center" }}>{formik.errors.subject}</span>
                    </tr>
                    <tr >
                        <td style={{ padding: "10px", borderRight: "2px solid #3b5175", textAlign: "center" }}>Add Question</td>
                        <td style={{ padding: "10px", textAlign: "center" }}> <button className="btn bg-primary" type="submit">Add Question</button></td>
                    </tr>
                </table>
            </form>
        </div>
    )
}